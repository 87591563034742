<template>
  <BasicModal
    :title="title"
    width="820px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <BasicForm
        ref="formRef"
        :gutter="[32, 0]"
        :formItemsMap="formItemsMap"
        v-model:formData="formData"
        @change="changeForm"
      ></BasicForm>
      <div class="edit-role-modal-content">
        <div class="edit-role-modal-content-left">
          <ModuleList
            v-model:selectValue="selectModule"
            :checkedValue="checkedModule"
            :list="moduleList"
            :loading="moduleListLoading"
          />
        </div>
        <div class="edit-role-modal-table">
          <div class="edit-role-modal-table-header">
            <div class="edit-role-modal-table-title">
              <span class="edit-role-modal-table-title-icon"></span>
              菜单列表
            </div>
          </div>
          <div class="edit-role-modal-table-body">
            <a-table
              size="small"
              rowKey="menuId"
              :row-selection="{
                checkStrictly: false,
                onChange: onSelectChange,
                selectedRowKeys: selectedRowKeys,
              }"
              :columns="menuColumns"
              :data-source="menuList"
              :pagination="false"
              :scroll="{ y: 320, x: 'max-content' }"
            >
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'permission'">
                  <component :is="$antIcons[record.menuIcon]" />
                  <span style="margin-left: 8px">{{ text }}</span>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </a-spin>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { apiRole, apiMenu } from "@/api/authority";
import { useUserStore } from "@/store/modules/user";
import { message } from "ant-design-vue";

const userStore = useUserStore();
const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.detail ? "修改" : "添加"}角色`);

onMounted(async () => {
  await initFormItems();
  initFormData();
  initModuleList();
});

const formItemsMap = reactive({
  tenantId: {
    label: "所属租户",
    prop: "tenantId",
    type: "select",
    options: [],
    fieldNames: {
      label: "tenantName",
      value: "tenantId",
    },
    required: true,
    requiredMessage: "请选择",
    span: 12,
    disabled: false,
  },
  name: {
    label: "角色名称",
    prop: "name",
    type: "input",
    required: true,
    requiredMessage: "请输入",
    span: 12,
  },
});
const loading = ref(false);
const initFormItems = async () => {
  formItemsMap.tenantId.disabled = !!props.detail || !userStore.isPlatformAdmin;
  loading.value = true;
  try {
    const { result } = await apiRole.tenants();
    formItemsMap.tenantId.options = result;
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
};
const changeForm = async ({ prop }) => {
  if (prop === "tenantId") {
    selectModule.value = {};
    formData.moduleMenuIds = {};
    initModuleList();
  }
};
const formData = reactive({
  tenantId: undefined,
  name: "",
  moduleMenuIds: {},
});
const initFormData = async () => {
  if (!props.detail) {
    // 新增
    if (userStore.isPlatformAdmin) {
      // 平台管理员
      const { options } = formItemsMap.tenantId;
      options.length && (formData.tenantId = options[0].tenantId);
    } else {
      // 租户管理员
      formData.tenantId = userStore.userInfo?.tenantId;
    }
  } else {
    // 修改
    const { tenantId, name, moduleMenuIds } = props.detail;
    formData.tenantId = Number(tenantId) || undefined;
    formData.name = name;
    moduleMenuIds.forEach((item) => {
      formData.moduleMenuIds[item.moduleCode] = item.menuIds;
    });
  }
};

const checkedModule = computed(() => {
  const _list = Object.keys(formData.moduleMenuIds || {});
  return _list;
});
const selectModule = ref({});
const moduleList = ref([]);
const moduleListLoading = ref(false);
const initModuleList = async () => {
  if (formData.tenantId) {
    moduleListLoading.value = true;
    try {
      const { result } = await apiMenu.menuListByTenant(formData.tenantId);
      moduleList.value = result;
      selectModule.value = result[0] || {};
    } catch (error) {
      console.log(error);
    }
    moduleListLoading.value = false;
  } else {
    moduleList.value = [];
  }
};
const menuColumns = [
  {
    title: "菜单标识",
    dataIndex: "permission",
  },
  {
    title: "中文显示",
    dataIndex: "menuName",
  },
  {
    title: "菜单地址",
    dataIndex: "menuPath",
  },
];
const menuList = computed(() => {
  return selectModule.value?.menus ?? [];
});
const selectedRowKeys = computed(() => {
  return formData.moduleMenuIds[selectModule.value.moduleCode] || [];
});
const onSelectChange = (selectedRowKeys) => {
  const { moduleCode } = selectModule.value;
  if (selectedRowKeys.length) {
    formData.moduleMenuIds[moduleCode] = selectedRowKeys;
  } else {
    delete formData.moduleMenuIds[moduleCode];
  }
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const hasModuleMenuIds = Object.keys(formData.moduleMenuIds || {}).length;
    if (hasModuleMenuIds) {
      const { tenantId, name, moduleMenuIds } = formData;
      const params = {
        name,
        menuIds: Object.values(moduleMenuIds).flat(Infinity),
        tenantId,
      };
      if (props.detail) {
        // 修改
        params.id = props.detail.id;
      } else {
        // 添加
        params.tenantId = tenantId;
      }
      confirmLoading.value = true;
      await apiRole[props.detail ? "edit" : "add"](params);
      message.success(`${props.detail ? "修改" : "添加"}成功`);
      emit("update:visible", false);
      emit("success");
      confirmLoading.value = false;
    } else {
      message.warning("请选择应用模块菜单");
    }
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.edit-role-modal-content {
  display: flex;
  height: 450px;
  border: 1px solid rgba(99, 116, 140, 0.2);
  &-left {
    width: 190px;
  }
  .edit-role-modal-table {
    flex: 1;
    border-left: 1px solid rgba(99, 116, 140, 0.2);
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      padding: 0 20px;
    }
    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      &-icon {
        width: 3px;
        height: 16px;
        background: #0256ff;
      }
    }
    &-body {
      padding: 0 20px 20px;
    }
  }
}
</style>
