<template>
  <div class="role-manage">
    <div class="role-manage-header">
      <CategorySearch :source="filterSource" @change="search" />
      <a-button type="primary" @click="showModal(null)">添加</a-button>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotStatus="{ row }">
        {{ roleStatusMap[row.status] }}
      </template>
      <template #slotRolePermissions="{ row }">
        <a-popover
          :title="`${row.name}-角色权限`"
          placement="leftBottom"
          trigger="click"
          destroyTooltipOnHide
          overlayClassName="role-manage-popover"
        >
          <template #content>
            <div class="auth-list">
              <div
                class="auth-item"
                v-for="(item, index) in row.rolePermissions"
                :key="index"
              >
                <div class="auth-item-header">
                  <span class="auth-item-header-line"></span>
                  <span class="auth-item-header-title">
                    <a-typography-text
                      :style="{ width: '100%' }"
                      :ellipsis="{ tooltip: item.moduleName }"
                      :content="item.moduleName"
                    />
                  </span>
                </div>
                <a-table
                  size="small"
                  rowKey="menuPermission"
                  :columns="authMenuColumns"
                  :data-source="item.menus"
                  :pagination="false"
                >
                  <template #bodyCell="{ column, text, record }">
                    <template v-if="column.dataIndex === 'menuPermission'">
                      <component :is="$antIcons[record.menuIcon]" />
                      <span style="margin-left: 8px">{{ text }}</span>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </template>
          <a-button type="link" size="small" class="role-manage-table-button">
            查看
          </a-button>
        </a-popover>
      </template>
      <template #slotUserNum="{ row }">
        <a-popover
          :title="`${row.name}-用户列表`"
          placement="leftBottom"
          trigger="click"
          destroyTooltipOnHide
          overlayClassName="role-manage-popover"
        >
          <template #content>
            <a-table
              style="width: 400px"
              size="small"
              rowKey="userId"
              :columns="userNumColumns"
              :data-source="row.userList"
              :pagination="false"
              :scroll="{ y: 300 }"
            />
          </template>
          <a-button type="link" size="small" class="role-manage-table-button">{{
            row.userNum
          }}</a-button>
        </a-popover>
      </template>
    </BasicTable>
  </div>
  <editRoleModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :detail="currentRow"
    @success="editSuccess"
  ></editRoleModal>
</template>
<script setup>
// defineOptions({
//   name: "RoleManage",
// });
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiRole } from "@/api/authority";
import { Modal, message } from "ant-design-vue";
import editRoleModal from "./components/editRoleModal";
import { roleStatusMap } from "./config";

const tableRef = ref();
const authMenuColumns = [
  {
    title: "菜单标识",
    dataIndex: "menuPermission",
  },
  {
    title: "中文显示",
    dataIndex: "menuName",
  },
];
const userNumColumns = [
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "用户姓名",
    dataIndex: "username",
  },
];

onMounted(() => {
  initTableData();
});

const filterSource = [
  {
    label: "租户名称",
    type: "input",
    bindKey: "tenantName",
    placeholder: "请输入",
  },
  {
    label: "角色名称",
    type: "input",
    bindKey: "name",
    placeholder: "请输入",
  },
];
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "角色ID",
      type: "text",
      key: "id",
      width: 150,
    },
    {
      title: "租户名称",
      type: "text",
      key: "tenantName",
      width: 300,
    },
    {
      title: "角色名称",
      type: "text",
      key: "name",
      width: 150,
    },
    {
      title: "角色状态",
      type: "customize",
      slotName: "slotStatus",
      width: 80,
    },
    {
      title: "角色权限",
      type: "customize",
      slotName: "slotRolePermissions",
      width: 80,
    },
    {
      title: "用户数量",
      type: "customize",
      slotName: "slotUserNum",
      width: 80,
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该条数据？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiRole
                  .delete(row.id)
                  .then(() => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    const res = await apiRole.page(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
  currentRow.value = row;
  modalVisible.value = true;
};
const editSuccess = () => {
  initTableData();
};
</script>
<style lang="less" scoped>
.role-manage {
  height: 100%;
  padding: 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>
<style lang="less">
.role-manage-popover {
  .ant-popover-title {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
  }
  .auth-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: calc(100vh - 500px);
    overflow-y: auto;
  }
  .auth-item {
    width: 400px;
    &-header {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 32px;
      &-line {
        width: 3px;
        height: 16px;
        background: #0256ff;
      }
      &-title {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>
